<template>
    <div class="ProductLibraryAdd">
        <!-- <div class="f-c-b"> -->
        <el-page-header @back="$router.go(-1)" :content="headerContent"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <div class="f-w boxShadow pt-40 pl-20 pr-20 mb-30" style="width: 95.5%;border-radius: 10px;">
                    <el-form ref="ProductLibraryAddForm" :model="ProductLibraryAddForm" label-width="110px"
                        class="ProductLibrary-Add-Form f-w" :rules="ProductLibraryAddRules">
                        <div class="formTitle ">产品基本信息 </div>
                        <el-form-item class="w--100" label="产品类型" prop="productType" key="productType">
                            <div class="f-w-b">
                                <div v-for="item in options" :key="item.value" class="productTypeItem"
                                    :class="ProductLibraryAddForm.productType == item.value ? 'selectY' : 'selectN'"
                                    @click="productTypeChange(item.value), typeDescribe = item.typeDescribe">
                                    {{ item.label }}
                                </div>
                            </div>
                            <div class="typeDescribe">类型说明：{{ typeDescribe }}</div>
                        </el-form-item>
                        <el-form-item class="w--50" v-for="item of ProductBasicInformation" :label="item.label"
                            :prop="item.prop" :key="item.prop">
                            <!-- 输入框 -->
                            <el-input v-if="item.chinestMaxLength && item.inputType == 'text'" type="text"
                                v-model="ProductLibraryAddForm[item.prop]" :placeholder="item.placeholderValue"
                                :maxlength="item.chinestMaxLength" clearable></el-input>
                            <!-- inputType -->
                            <el-input v-if="item.inputType == 'textarea'" type="textarea"
                                v-model="ProductLibraryAddForm[item.prop]" :placeholder="item.placeholderValue"
                                :maxlength="item.chinestMaxLength" clearable></el-input>
                            <!-- 产品单价 -->
                            <div v-if="item.prop == 'unitPrice'" class="f-c-b Product-unit-price">
                                <DecimalInput class="mr-10" :placeholderText="item.placeholderValue"
                                    :modelValue="ProductLibraryAddForm[item.prop]" @DecimalInputEvent="DecimalInputEvent"
                                    :typeValue="item.prop"></DecimalInput>
                                <SelectOption :selectModel="ProductLibraryAddForm.valuationCurrency" :Option="CurencyOption"
                                    :type="'ValuationCurrency'" @selectValuationCurrency="selectValuationCurrency">
                                </SelectOption>
                            </div>
                            <!-- 计价单位 -->
                            <el-select v-if="item.prop == 'attributeValue1' && ProductLibraryAddForm.productType != 999"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" @change="selectAttributeValue1"
                                class="select">
                                <el-option v-for="item in attributeValue1Options" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                            <div class="f-c"
                                v-else-if="item.prop == 'attributeValue1' && ProductLibraryAddForm.productType == 999">
                                <span style="margin-right: 10px">/</span>
                                <el-input v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    placeholder="请输入计价单位"></el-input>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>


                <div v-if="ProductParameter.length != 0" class="boxShadow pt-40 pl-20 pr-20 mb-30"
                    style="width: 95.5%;border-radius: 10px;">
                    <el-form ref="ProductLibraryAddForm" :model="ProductLibraryAddForm" label-width="110px"
                        class="ProductLibrary-Add-Form f-w" :rules="ProductLibraryAddRules">
                        <div class="w--100 h-30 f-c-b mb-20" style="width:100%;">
                            <div class="formTitle1">产品参数 </div>
                            <span class="w-80 deploymentBtn" @click="productparametersList = !productparametersList">展开 <i
                                    :class="productparametersList ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></span>
                        </div>
                        <el-form-item v-show="productparametersList" :class="item.monopolizeLine ? 'w--100' : 'w--50'"
                            v-for="item of ProductParameter" :label="item.label" :prop="item.prop" :key="item.prop">
                            <el-input v-if="item.chinestMaxLength" type="text" v-model="ProductLibraryAddForm[item.prop]"
                                :placeholder="item.placeholderValue" :maxlength="item.chinestMaxLength"
                                clearable></el-input>

                            <ChineseCharactersInput v-if="item.MaxLength"
                                :modelValue="ProductLibraryAddForm.productAttribute[item.prop]" :type="item.prop"
                                :placeholderValue="item.placeholderValue" :maxlength="item.MaxLength" @getValue="getValue2"
                                :inputType="item.inputType" :TextAreaisLimit="item.TextAreaisLimit">
                            </ChineseCharactersInput>
                            <!-- 是否 -->
                            <el-radio-group v-show="item.isCustomMade"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]">
                                <el-radio label="Y">是</el-radio>
                                <el-radio label="N">否</el-radio>
                            </el-radio-group>
                            <!-- 最大~最小 -->
                            <div class="f-c" v-if="item.isDecimalInput">
                                <el-input type="number" v-model="ProductLibraryAddForm.productAttribute[item.prop1]"
                                    :placeholder="item.placeholderText1" maxlength="8" clearable></el-input>
                                <span class="interval">~</span>
                                <el-input type="number" v-model="ProductLibraryAddForm.productAttribute[item.prop2]"
                                    :placeholder="item.placeholderText2" maxlength="8" clearable></el-input>
                                <!--  -->
                                <span class="interval" v-if="item.label == '厚度' || item.label == '硬度'">{{ item.label == '厚度'
                                    ?
                                    ProductLibraryAddForm.productAttribute.attributeValue4 :
                                    ProductLibraryAddForm.productAttribute.attributeValue6
                                }}</span>
                            </div>
                            <!-- 1.工艺 -->
                            <el-cascader v-show="item.isSelectOption && ProductLibraryAddForm.productType == 1"
                                class="select" separator="->" v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                :options="item.label == '工艺' ? WorkmanshipOptions : MaterialOptions"
                                @change="item.label == '工艺' ? handleAttributeValue7Change : handleAttributeValue2Change">
                            </el-cascader>
                            <!-- 1.张幅单位 -->
                            <el-select v-if="item.prop == 'attributeValue13' && ProductLibraryAddForm.productType == 1"
                                v-model="ProductLibraryAddForm.productAttribute.attributeValue13"
                                @change="selectAttributeValue13" class="select">
                                <el-option label="平方英尺/张" value="平方英尺/张"></el-option>
                                <el-option label="平方尺/张" value="平方尺/张"></el-option>
                            </el-select>
                            <!-- 1.等级 -->
                            <el-select v-if="item.prop == 'attributeValue8' && ProductLibraryAddForm.productType == 1"
                                v-model="ProductLibraryAddForm.productAttribute.attributeValue8"
                                @change="selectAttributeValue8" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="一级" value="一级"></el-option>
                                <el-option label="二级" value="二级"></el-option>
                                <el-option label="三级" value="三级"></el-option>
                                <el-option label="四级" value="四级"></el-option>
                            </el-select>

                            <!-- 2.厚度 -->
                            <div v-if="item.prop == 'attributeValue6' && ProductLibraryAddForm.productType == 2"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue7" class="select">
                                    <el-option label="mm" value="mm"></el-option>
                                </el-select>
                            </div>
                            <!-- 2.底基类型 -->
                            <el-select v-if="item.prop == 'attributeValue2' && ProductLibraryAddForm.productType == 2"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="佳积布" value="佳积布"></el-option>
                                <el-option label="弹力布" value="弹力布"></el-option>
                                <el-option label="无纺布" value="无纺布"></el-option>
                                <el-option label="超细纤维" value="超细纤维"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 2.工艺类型 -->
                            <el-select v-if="item.prop == 'attributeValue3' && ProductLibraryAddForm.productType == 2"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select"
                                value-key="value">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="喷色" value="喷色"></el-option>
                                <el-option label="贴膜" value="贴膜"></el-option>
                                <el-option label="压花" value="压花"></el-option>
                                <el-option label="印花" value="印花"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 2.风格类型 -->
                            <el-select v-if="item.prop == 'attributeValue4' && ProductLibraryAddForm.productType == 2"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置" key="未设置"></el-option>
                                <el-option label="幻彩" value="幻彩" key="幻彩"></el-option>
                                <el-option label="亮面" value="亮面" key="亮面"></el-option>
                                <el-option label="镭射" value="镭射" key="镭射"></el-option>
                                <el-option label="磨砂" value="磨砂" key="磨砂"></el-option>
                                <el-option label="哑光" value="哑光" key="哑光"></el-option>
                                <el-option label="其它" value="其它" key="其它"></el-option>
                            </el-select>
                            <!-- 2.产品功能 -->
                            <el-select v-if="item.prop == 'attributeValue5' && ProductLibraryAddForm.productType == 2"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="防水" value="防水"></el-option>
                                <el-option label="抗菌" value="抗菌"></el-option>
                                <el-option label="透气" value="透气"></el-option>
                                <el-option label="吸水" value="吸水"></el-option>
                                <el-option label="耐变黄" value="耐变黄"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 2.张幅单位 -->
                            <el-select v-if="item.prop == 'attributeValue10' && ProductLibraryAddForm.productType == 2"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="/码" value="/码"></el-option>
                                <el-option label="/米" value="/米"></el-option>
                                <el-option label="/平方英尺" value="/平方英尺"></el-option>
                                <el-option label="/平方尺" value="/平方尺"></el-option>
                            </el-select>



                            <!-- 3.材质 -->
                            <el-select v-if="item.prop == 'attributeValue2' && ProductLibraryAddForm.productType == 3"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="铜" value="铜"></el-option>
                                <el-option label="铁" value="铁"></el-option>
                                <el-option label="铝" value="铝"></el-option>
                                <el-option label="锌合金" value="锌合金"></el-option>
                                <el-option label="不锈钢" value="不锈钢"></el-option>
                            </el-select>
                            <!-- 3.工艺 -->
                            <div v-if="item.prop == 'attributeValue3' && ProductLibraryAddForm.productType == 3">
                                <el-select v-if="item.prop == 'attributeValue3' && ProductLibraryAddForm.productType == 3"
                                    @change="HardwareattributeValue3"
                                    v-model="ProductLibraryAddForm.productAttribute.attributeValue3list" multiple
                                    class="select" value-key="value">
                                    <el-option label="开模" value="开模" key="开模"></el-option>
                                    <el-option label="压铸" value="压铸" key="压铸"></el-option>
                                    <el-option label="洗水" value="洗水" key="洗水"></el-option>
                                    <el-option label="夹针" value="夹针" key="夹针"></el-option>
                                    <el-option label="夹管" value="夹管" key="夹管"></el-option>
                                    <el-option label="滚电" value="滚电" key="滚电"></el-option>
                                    <el-option label="挂电" value="挂电" key="挂电"></el-option>
                                    <el-option label="烤漆" value="烤漆" key="烤漆"></el-option>
                                </el-select>
                            </div>

                            <!-- 3.尺寸单位 -->
                            <el-select v-if="item.prop == 'attributeValue7' && ProductLibraryAddForm.productType == 3"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="mm" value="mm"></el-option>
                                <el-option label="cm" value="cm"></el-option>
                            </el-select>
                            <!-- 3.线粗 -->
                            <div v-if="item.prop == 'attributeValue8' && ProductLibraryAddForm.productType == 3"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue9" class="select">
                                    <el-option label="mm" value="mm"></el-option>
                                    <el-option label="cm" value="cm"></el-option>
                                </el-select>
                            </div>
                            <!-- 3.重量 -->
                            <div v-if="item.prop == 'attributeValue10' && ProductLibraryAddForm.productType == 3"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue11" class="select">
                                    <el-option label="g" value="g"></el-option>
                                </el-select>
                            </div>

                            <!-- 4.类型 -->
                            <el-select v-if="item.prop == 'attributeValue2' && ProductLibraryAddForm.productType == 4"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="大底" value="大底"></el-option>
                                <el-option label="鞋跟" value="鞋跟"></el-option>
                            </el-select>
                            <!-- 4.材质 -->
                            <el-select v-if="item.prop == 'attributeValue3' && ProductLibraryAddForm.productType == 4"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="爆米花" value="爆米花"></el-option>
                                <el-option label="二次发泡（XQR）" value="二次发泡（XQR）"></el-option>
                                <el-option label="二次MD" value="二次MD"></el-option>
                                <el-option label="聚氨酯（PU）" value="聚氨酯（PU）"></el-option>
                                <el-option label="气垫" value="气垫"></el-option>
                                <el-option label="轻质发泡（QRB）" value="轻质发泡（QRB）"></el-option>
                                <el-option label="橡胶（RB）" value="橡胶（RB）"></el-option>
                                <el-option label="橡胶发泡（TCR）" value="橡胶发泡（TCR）"></el-option>
                                <el-option label="EVA" value="EVA"></el-option>
                                <el-option label="PVC" value="PVC"></el-option>
                                <el-option label="TPR" value="TPR"></el-option>
                                <el-option label="TPU" value="TPU"></el-option>
                                <el-option label="TR" value="TR"></el-option>
                                <el-option label="TRB" value="TRB"></el-option>
                                <el-option label="TPU + PU" value="TPU + PU"></el-option>
                                <el-option label="橡胶（RB） + PU" value="橡胶（RB） + PU"></el-option>
                                <el-option label="橡胶（RB） + 发泡" value="橡胶（RB） + 发泡"></el-option>
                                <el-option label="二次MD + TPR + 气垫" value="二次MD + TPR + 气垫"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 4.工艺 -->
                            <el-select v-if="item.prop == 'attributeValue4' && ProductLibraryAddForm.productType == 4"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="擦色" value="擦色"></el-option>
                                <el-option label="车翻（车线）" value="车翻（车线）"></el-option>
                                <el-option label="渡色" value="渡色"></el-option>
                                <el-option label="喷漆" value="喷漆"></el-option>
                                <el-option label="贴沿条" value="贴沿条"></el-option>
                                <el-option label="围边" value="围边"></el-option>
                                <el-option label="组合" value="组合"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 4.尺码范围 -->
                            <div class="f-c" v-if="item.isRadiusInput">
                                <el-input type="number" v-model="ProductLibraryAddForm.productAttribute[item.prop1]"
                                    :placeholder="item.placeholderText1" maxlength="8" clearable></el-input>
                                <span class="interval">|</span>
                                <el-input type="number" v-model="ProductLibraryAddForm.productAttribute[item.prop2]"
                                    :placeholder="item.placeholderText2" maxlength="8" clearable></el-input>
                            </div>
                            <!-- 4.尺码单位 -->
                            <el-select v-if="item.prop == 'attributeValue7' && ProductLibraryAddForm.productType == 4"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="国际码" value="国际码"></el-option>
                                <el-option label="欧码" value="欧码"></el-option>
                                <el-option label="美码" value="美码"></el-option>
                            </el-select>
                            <!-- 4.风格 -->
                            <el-select v-if="item.prop == 'attributeValue8' && ProductLibraryAddForm.productType == 4"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="正装鞋" value="正装鞋"></el-option>
                                <el-option label="时装鞋" value="时装鞋"></el-option>
                                <el-option label="高跟鞋" value="高跟鞋"></el-option>
                                <el-option label="运动鞋" value="运动鞋"></el-option>
                                <el-option label="商务鞋" value="商务鞋"></el-option>
                                <el-option label="时尚潮鞋" value="时尚潮鞋"></el-option>
                                <el-option label="滑板鞋" value="滑板鞋"></el-option>
                                <el-option label="马丁靴" value="马丁靴"></el-option>
                                <el-option label="凉鞋/拖鞋" value="凉鞋/拖鞋"></el-option>
                                <el-option label="豆豆鞋" value="豆豆鞋"></el-option>
                                <el-option label="拉线鞋" value="拉线鞋"></el-option>
                                <el-option label="雪地靴" value="雪地靴"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 4.功能 -->
                            <el-select v-if="item.prop == 'attributeValue9' && ProductLibraryAddForm.productType == 4"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="按摩" value="按摩"></el-option>
                                <el-option label="防滑" value="防滑"></el-option>
                                <el-option label="减震" value="减震"></el-option>
                                <el-option label="抗静电" value="抗静电"></el-option>
                                <el-option label="耐油" value="耐油"></el-option>
                                <el-option label="轻便" value="轻便"></el-option>
                                <el-option label="透明" value="透明"></el-option>
                                <el-option label="透气" value="透气"></el-option>
                                <el-option label="增高" value="增高"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 4.腰位(宽) -->
                            <div v-if="item.prop == 'attributeValue14' && ProductLibraryAddForm.productType == 4"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue15" class="select">
                                    <el-option label="mm" value="mm"></el-option>
                                    <el-option label="cm" value="cm"></el-option>
                                </el-select>
                            </div>

                            <!-- 5.材质类型 -->
                            <el-select v-if="item.prop == 'attributeValue2' && ProductLibraryAddForm.productType == 5"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="网布" value="网布"></el-option>
                                <el-option label="编织布" value="编织布"></el-option>
                                <el-option label="弹力布" value="弹力布"></el-option>
                                <el-option label="亮片布" value="亮片布"></el-option>
                                <el-option label="沙丁布" value="沙丁布"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 5.厚度 -->
                            <div v-if="item.prop == 'attributeValue3' && ProductLibraryAddForm.productType == 5"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue4" class="select">
                                    <el-option label="mm" value="mm"></el-option>
                                </el-select>
                            </div>
                            <!-- 5.宽度 -->
                            <div v-if="item.prop == 'attributeValue5' && ProductLibraryAddForm.productType == 5"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue6" class="select">
                                    <el-option label="码" value="码"></el-option>
                                    <el-option label="米" value="米"></el-option>
                                    <el-option label="尺" value="尺"></el-option>
                                    <el-option label="cm" value="cm"></el-option>
                                </el-select>
                            </div>
                            <!-- 5.最大长度 -->
                            <div v-if="item.prop == 'attributeValue7' && ProductLibraryAddForm.productType == 5"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue8" class="select">
                                    <el-option label="码" value="码"></el-option>
                                    <el-option label="米" value="米"></el-option>
                                    <el-option label="尺" value="尺"></el-option>
                                    <el-option label="cm" value="cm"></el-option>
                                </el-select>
                            </div>
                            <!-- 6.材质类型 -->
                            <el-select v-if="item.prop == 'attributeValue2' && ProductLibraryAddForm.productType == 6"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="麻" value="麻"></el-option>
                                <el-option label="纸" value="纸"></el-option>
                                <el-option label="线" value="线"></el-option>
                                <el-option label="超纤" value="超纤"></el-option>
                                <el-option label="涤纶" value="涤纶"></el-option>
                                <el-option label="木皮" value="木皮"></el-option>
                                <el-option label="皮革" value="皮革"></el-option>
                                <el-option label="拉菲草" value="拉菲草"></el-option>
                                <el-option label="玛特草" value="玛特草"></el-option>
                                <el-option label="PP" value="PP"></el-option>
                                <el-option label="PET" value="PET"></el-option>
                                <el-option label="PU革" value="PU革"></el-option>
                                <el-option label="PVC" value="PVC"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 6.编织类型 -->
                            <el-select v-if="item.prop == 'attributeValue3' && ProductLibraryAddForm.productType == 6"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="十字" value="十字"></el-option>
                                <el-option label="线编" value="线编"></el-option>
                                <el-option label="手工编织" value="手工编织"></el-option>
                                <el-option label="机器编织" value="机器编织"></el-option>
                                <el-option label="半手工编织" value="半手工编织"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 6.厚度 -->
                            <div v-if="item.prop == 'attributeValue4' && ProductLibraryAddForm.productType == 6"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue5" class="select">
                                    <el-option label="mm" value="mm"></el-option>
                                </el-select>
                            </div>
                            <!-- 6.宽度 -->
                            <div v-if="item.prop == 'attributeValue6' && ProductLibraryAddForm.productType == 6"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue7" class="select">
                                    <el-option label="码" value="码"></el-option>
                                    <el-option label="米" value="米"></el-option>
                                    <el-option label="尺" value="尺"></el-option>
                                    <el-option label="cm" value="cm"></el-option>
                                </el-select>
                            </div>
                            <!-- 6.最大长度 -->
                            <div v-if="item.prop == 'attributeValue8' && ProductLibraryAddForm.productType == 6"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue9" class="select">
                                    <el-option label="码" value="码"></el-option>
                                    <el-option label="米" value="米"></el-option>
                                    <el-option label="尺" value="尺"></el-option>
                                    <el-option label="cm" value="cm"></el-option>
                                </el-select>
                            </div>
                            <!-- 7.风格 -->
                            <el-select v-if="item.prop == 'attributeValue4' && ProductLibraryAddForm.productType == 7"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="正装鞋" value="正装鞋"></el-option>
                                <el-option label="时装鞋" value="时装鞋"></el-option>
                                <el-option label="高跟鞋" value="高跟鞋"></el-option>
                                <el-option label="运动鞋" value="运动鞋"></el-option>
                                <el-option label="商务鞋" value="商务鞋"></el-option>
                                <el-option label="时尚潮鞋" value="时尚潮鞋"></el-option>
                                <el-option label="滑板鞋" value="滑板鞋"></el-option>
                                <el-option label="马丁靴" value="马丁靴"></el-option>
                                <el-option label="凉鞋/拖鞋" value="凉鞋/拖鞋"></el-option>
                                <el-option label="豆豆鞋" value="豆豆鞋"></el-option>
                                <el-option label="拉线鞋" value="拉线鞋"></el-option>
                                <el-option label="雪地靴" value="雪地靴"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 7.功能 -->
                            <el-select v-if="item.prop == 'attributeValue5' && ProductLibraryAddForm.productType == 7"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="按摩" value="按摩"></el-option>
                                <el-option label="防滑" value="防滑"></el-option>
                                <el-option label="减震" value="减震"></el-option>
                                <el-option label="抗静电" value="抗静电"></el-option>
                                <el-option label="耐油" value="耐油"></el-option>
                                <el-option label="轻便" value="轻便"></el-option>
                                <el-option label="透明" value="透明"></el-option>
                                <el-option label="透气" value="透气"></el-option>
                                <el-option label="增高" value="增高"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                            <!-- 7.尺码单位 -->
                            <el-select v-if="item.prop == 'attributeValue8' && ProductLibraryAddForm.productType == 7"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="国际码" value="国际码"></el-option>
                                <el-option label="欧码" value="欧码"></el-option>
                                <el-option label="美码" value="美码"></el-option>
                            </el-select>
                            <!-- 8.款式 -->
                            <el-select v-if="item.prop == 'attributeValue2' && ProductLibraryAddForm.productType == 8"
                                v-model="ProductLibraryAddForm.productAttribute[item.prop]" class="select">
                                <el-option label="未设置" value="未设置"></el-option>
                                <el-option label="公文包" value="公文包"></el-option>
                                <el-option label="手包" value="手包"></el-option>
                                <el-option label="钱包" value="钱包"></el-option>
                                <el-option label="斜挎包" value="斜挎包"></el-option>
                                <el-option label="胸包" value="胸包"></el-option>
                                <el-option label="旅行包" value="旅行包"></el-option>
                                <el-option label="背包" value="背包"></el-option>
                                <el-option label="腰包" value="腰包"></el-option>
                            </el-select>
                            <!-- 8.规格(高) -->
                            <div v-if="item.prop == 'attributeValue7' && ProductLibraryAddForm.productType == 8"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue8" class="select">
                                    <el-option label="cm" value="cm"></el-option>
                                    <el-option label="mm" value="mm"></el-option>
                                </el-select>
                            </div>
                            <!-- 8.容量 -->
                            <div v-if="item.prop == 'attributeValue9' && ProductLibraryAddForm.productType == 8"
                                class="f-c-b Product-unit-price">
                                <el-input class="mr-10" type="number"
                                    v-model="ProductLibraryAddForm.productAttribute[item.prop]"
                                    :placeholder="item.placeholderValue" maxlength="8" clearable></el-input>
                                <el-select v-model="ProductLibraryAddForm.productAttribute.attributeValue10" class="select">
                                    <el-option label="寸" value="寸"></el-option>
                                    <el-option label="英寸" value="英寸"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="boxShadow pt-40 pl-20 pr-20 mb-30" style="width: 95.5%;border-radius: 10px;">
                    <el-form ref="ProductLibraryAddForm" :model="ProductLibraryAddForm" label-width="110px"
                        class="ProductLibrary-Add-Form f-w" :rules="ProductLibraryAddRules">
                        <div class="w--100 h-30 f-c-b mb-20" style="width:100%;">
                            <div class="formTitle1">平台搜索设置</div>
                            <span class="w-80 deploymentBtn" @click="platformsearchsettings = !platformsearchsettings">展开 <i
                                    :class="platformsearchsettings ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></span>
                        </div>
                        <el-form-item v-show="platformsearchsettings" class="w--100" label="开放对外搜索" prop="openSearchFlag"
                            key="openSearchFlag">
                            <el-radio-group v-model="ProductLibraryAddForm.openSearchFlag">
                                <el-radio label="Y">是</el-radio>
                                <el-radio label="N">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-show="platformsearchsettings" class="w--100" label="对外显示缺货" prop="shortageFlag"
                            key="shortageFlag">
                            <el-radio-group v-model="ProductLibraryAddForm.shortageFlag">
                                <el-radio label="Y">是</el-radio>
                                <el-radio label="N">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                </div>


                <div class="boxShadow pt-40 pl-20 pr-20 mb-30" style="width: 95.5%;border-radius: 10px;">
                    <el-form ref="ProductLibraryAddForm" :model="ProductLibraryAddForm" label-width="110px"
                        class="ProductLibrary-Add-Form f-w" :rules="ProductLibraryAddRules">
                        <div class="w--100 h-30 f-c-b mb-20" style="width:100%;">
                            <div class="formTitle1">产品展示</div>
                            <span class="w-80 deploymentBtn" @click="productdisplay = !productdisplay">展开 <i
                                    :class="productdisplay ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></span>
                        </div>
                        <el-form-item v-show="productdisplay" class="w--50" label="产品主图" prop="img1Url" key="img1Url">
                            <div v-if="ProductLibraryAddForm.img1Url && ProductLibraryAddForm.img1Url != 'D'"
                                style="position: relative;">
                                <el-image style="width: 200px; height: 200px" fit="contain"
                                    :src="ProductLibraryAddForm.img1Url"></el-image>
                                <i @click="deleteUploadImage('img1Url')" class="delete-btn el-icon-close"></i>
                            </div>
                            <div v-else class="f-c-c"
                                style="width: 300px;height: 178px;border-radius: 10px;border: 1px dashed #d9d9d9;cursor: pointer;"
                                @click="showImageUploadBox1 = true">
                                <i style="font-size: 32px;color: #0675F3;"
                                    class="el-icon-circle-plus avatar-uploader-icon"></i>
                            </div>
                            <UploadImageBox @closeUploadBox="closeUploadBox" v-show="showImageUploadBox1"
                                @FormData="FormData" @FormData2="FormData2" :imageUploadTrends="imageUploadTrends1" imageDiff="img1Url"
                                :key="new Date().getTime() + 1"></UploadImageBox>
                        </el-form-item>
                        <el-form-item v-show="productdisplay" class="w--50" label="产品图2" prop="img2Url" key="img2Url">
                            <div v-if="ProductLibraryAddForm.img2Url && ProductLibraryAddForm.img2Url != 'D'"
                                style="position: relative;">
                                <el-image style="width: 200px; height: 200px" fit="contain"
                                    :src="ProductLibraryAddForm.img2Url"></el-image>
                                <i @click="deleteUploadImage('img2Url')" class="delete-btn el-icon-close"></i>
                            </div>
                            <div v-else class="f-c-c"
                                style="width: 300px;height: 178px;border-radius: 10px;border: 1px dashed #d9d9d9;cursor: pointer;"
                                @click="showImageUploadBox2 = true">
                                <i style="font-size: 32px;color: #0675F3;"
                                    class="el-icon-circle-plus avatar-uploader-icon"></i>
                            </div>
                            <UploadImageBox @closeUploadBox="closeUploadBox" v-show="showImageUploadBox2"
                                @FormData="FormData" @FormData2="FormData2" :imageUploadTrends="imageUploadTrends1" imageDiff="img2Url"
                                :key="new Date().getTime() + 2"></UploadImageBox>





                            <!-- 上传图片 -->
                            <!-- <UploadImageDiscern :imageUrl="ProductLibraryAddForm.img2Url" imageDiff="img2Url"
                                @FormData="FormData" @FormData2="FormData2" @deleteUploadImage="deleteUploadImage"
                                :key="new Date().getTime() + 2">
                            </UploadImageDiscern> -->
                        </el-form-item>
                        <el-form-item v-show="productdisplay" class="w--50" label="产品图3" prop="img3Url" key="img3Url">
                            <div v-if="ProductLibraryAddForm.img3Url && ProductLibraryAddForm.img3Url != 'D'"
                                style="position: relative;">
                                <el-image style="width: 200px; height: 200px" fit="contain"
                                    :src="ProductLibraryAddForm.img3Url"></el-image>
                                <i @click="deleteUploadImage('img3Url')" class="delete-btn el-icon-close"></i>
                            </div>
                            <div v-else class="f-c-c"
                                style="width: 300px;height: 178px;border-radius: 10px;border: 1px dashed #d9d9d9;cursor: pointer;"
                                @click="showImageUploadBox3 = true">
                                <i style="font-size: 32px;color: #0675F3;"
                                    class="el-icon-circle-plus avatar-uploader-icon"></i>
                            </div>
                            <UploadImageBox @closeUploadBox="closeUploadBox" v-show="showImageUploadBox3"
                                @FormData="FormData" @FormData2="FormData2" :imageUploadTrends="imageUploadTrends1" imageDiff="img3Url"
                                :key="new Date().getTime() + 3"></UploadImageBox>




                            <!-- 上传图片 -->
                            <!-- <UploadImageDiscern :imageUrl="ProductLibraryAddForm.img3Url" imageDiff="img3Url"
                                @FormData="FormData" @FormData2="FormData2" @deleteUploadImage="deleteUploadImage"
                                :key="new Date().getTime() + 3">
                            </UploadImageDiscern> -->
                        </el-form-item>
                        <el-form-item v-show="productdisplay" class="w--50" label="产品图4" prop="img4Url" key="img4Url">
                            <div v-if="ProductLibraryAddForm.img4Url && ProductLibraryAddForm.img4Url != 'D'"
                                style="position: relative;">
                                <el-image style="width: 200px; height: 200px" fit="contain"
                                    :src="ProductLibraryAddForm.img4Url"></el-image>
                                <i @click="deleteUploadImage('img4Url')" class="delete-btn el-icon-close"></i>
                            </div>
                            <div v-else class="f-c-c"
                                style="width: 300px;height: 178px;border-radius: 10px;border: 1px dashed #d9d9d9;cursor: pointer;"
                                @click="showImageUploadBox4 = true">
                                <i style="font-size: 32px;color: #0675F3;"
                                    class="el-icon-circle-plus avatar-uploader-icon"></i>
                            </div>
                            <UploadImageBox @closeUploadBox="closeUploadBox" v-show="showImageUploadBox4"
                                @FormData="FormData" @FormData2="FormData2" :imageUploadTrends="imageUploadTrends1" imageDiff="img4Url"
                                :key="new Date().getTime() + 4"></UploadImageBox>






                            <!-- 上传图片 -->
                            <!-- <UploadImageDiscern :imageUrl="ProductLibraryAddForm.img4Url" imageDiff="img4Url"
                                @FormData="FormData" @FormData2="FormData2" @deleteUploadImage="deleteUploadImage"
                                :key="new Date().getTime() + 3">
                            </UploadImageDiscern> -->
                        </el-form-item>
                        <el-form-item v-show="productdisplay" class="w--100" label="产品视频" prop="videoUrl" key="videoUrl">
                            <!-- 上传视频 -->
                            <UploadVideo :videoUrl="ProductLibraryAddForm.videoUrl" @FormData="FormData"
                                @deleteUploadVideo="deleteUploadVideo"></UploadVideo>
                        </el-form-item>
                        <el-form-item v-show="productdisplay" class="w--100" label="产品简介" prop="introduction"
                            key="introduction">
                            <!-- 简介 -->
                            <el-input type="textarea" v-model="ProductLibraryAddForm.introduction" placeholder="编辑产品简介"
                                maxlength="100" clearable :show-word-limit="true"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="f-c-c add-btns w--100 mb-40">
                    <div class="sure" @click="addProduct('ProductLibraryAddForm')">确定新增</div>
                    <div class="back ml-40" @click="$router.go(-1)">返回</div>
                </div>


            </div>
        </div>
        <!-- <el-button v-if="queryType == 'modify'" type="danger" -->
        <!-- @click="deleteProduct(ProductLibraryAddForm.productId)">删除</el-button> -->
        <!-- </div> -->
        <!-- <div class="marginTB-30 fw-bold">产品基本信息</div> -->
        <!-- <Dialog title="调色板" width="25%" :visible="showPaletteDialog" class="dialog-box f-cl-c"
            @closeDialog="showPaletteDialog = false">
        </Dialog> -->
    </div>
</template>

<script>
import Material from '@/assets/Material.json'
import Workmanship from '@/assets/Workmanship.json'
import { Decimal } from 'decimal.js'
import { CurrencyOption } from '@/utils/SelectOption'
import { ProductLibraryAddRules } from "@/utils/FormRules"
import { UnitAccount } from "@/utils/attributeValue1Options"
import { UrlDecodeIdType } from '@/mixins/UrlDecodeMixin';
import RGB2Lab from '@/utils/RGB2Lab'
// import {storage} from "@/utils/common";

export default {
    name: "ProductLibraryAdd",
    components:
    {
        UploadVideo: () => import(/* webpackChunkName: "UploadVideo" */ '../components/UploadVideo.vue'),
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue'),
        // UploadImageDiscern: () => import(/* webpackChunkName: "UploadVideo" */ '../components/UploadImage.vue'),
        UploadImageBox: () => import(/* webpackChunkName: "UploadVideo" */ '../components/UploadImage2.vue')
    },
    mixins: [UrlDecodeIdType],

    data() {
        return {
            ProductLibraryAddForm: {
                productType: '', // 产品类型
                options: [],
                productName: "",
                productNumber: "",
                colorStr: "",
                rgbValue: "",
                unitPrice: 0,
                valuationCurrency: "￥",//产品单价单位
                openSearchFlag: "Y",//开放搜索标记
                shortageFlag: "N",//缺货标记
                img1Url: "",
                img2Url: "",
                img3Url: "",
                img4Url: "",
                videoUrl: "",
                introduction: "", //产品简介
                // 产品参数
                productAttribute: {}
            },
            // 产品
            ProductLibraryAddFormAr: [],
            // 基本信息
            ProductBasicInformation: [
                {
                    label: "产品名称：",
                    prop: "productName",
                    placeholderValue: '请输入产品名称',
                    chinestMaxLength: '30',
                    inputType: 'textarea',
                    TextAreaisLimit: false
                }, {
                    label: "内部编号：",
                    prop: "productNumber",
                    placeholderValue: '请输入内部编号',
                    chinestMaxLength: '30',
                    inputType: 'textarea',
                    TextAreaisLimit: false
                }, {
                    label: "颜色名称",
                    prop: "colorStr",
                    placeholderValue: '请输入颜色名称',
                    chinestMaxLength: '30',
                    inputType: 'textarea',
                    TextAreaisLimit: false
                }, {
                    label: "产品单价：",
                    prop: "unitPrice",
                    placeholderValue: '请输入产品单价',
                }, {
                    label: "计价单位",
                    prop: "attributeValue1",
                },
            ],
            // 产品参数
            ProductParameter: [],
            // 产品展示
            ProductDisplay: [
                {
                    label: "平台搜索设置",
                    isTitle: true
                }, {
                    label: "开放对外搜索",
                    prop: "openSearchFlag",
                    isSelectYN: true
                }, {
                    label: "对外显示缺货",
                    prop: "shortageFlag",
                    isSelectYN: true
                }, {
                    label: "产品展示",
                    isTitle: true
                }, {
                    label: "产品主图",
                    prop: "img1Url",
                    isImg: true,
                    disabledChange: false
                }, {
                    label: "产品图2",
                    prop: "img2Url",
                    isImg: true,
                    disabledChange: false
                }, {
                    label: "产品图3",
                    prop: "img3Url",
                    isImg: true,
                    disabledChange: false
                }, {
                    label: "产品图4",
                    prop: "img4Url",
                    isImg: true,
                    disabledChange: false
                }, {
                    label: "产品视频",
                    prop: "videoUrl",
                    isVideo: true
                }, {
                    label: "产品简介",
                    prop: "introduction",
                    placeholderValue: '编辑产品简介',
                    textareaMaxLength: '100',
                    inputType: 'textarea',
                    TextAreaisLimit: true
                },
            ],
            WorkmanshipOptions: Workmanship,
            MaterialOptions: Material,
            showPaletteDialog: false,
            ProductLibraryAddRules,
            CurencyOption: CurrencyOption,//货币类型
            orginImg1Url: '',
            orginImg2Url: '',
            orginImg3Url: '',
            orginImg4Url: '',
            orginVideoUrl: '',
            headerContent: '新增产品',
            timer: null,
            TIME_COUNT: 21,
            count: 0,
            labValue: {},
            options: [],
            attributeValue1Options: [
                {
                    value: '/平方英尺',
                    label: '/平方英尺'
                }, {
                    value: '/平方尺',
                    label: '/平方尺'
                }
            ],
            DermisUnitproductAttribute: {  // 真皮
                attributeValue1: "/平方英尺",//计价单位单位
                attributeValue7: [],//工艺
                attributeValue2: "",//材质
                attributeValue3: "",//最小厚度
                attributeValue14: "",//最大厚度
                attributeValue4: "mm",//厚度单位
                attributeValue5: "",//硬度最小
                attributeValue15: "",//硬度最大
                attributeValue6: "N/mm²",//硬度单位
                attributeValue9: "Y",//接受定制花纹
                attributeValue10: "Y",//接受定制厚度
                attributeValue11: "Y",// 接受定制工艺
                attributeValue12: "",//平均张幅（最小）
                attributeValue16: "",//平均张幅（最大）
                attributeValue13: "平方英尺/张",//张幅单位
                attributeValue8: "未设置",//等级
            },
            ArtificialLeatherproductAttribute: {  // 人造革
                attributeValue1: "/码",//计价单位单位
                attributeValue2: "未设置",
                attributeValue3: "未设置",//
                attributeValue4: "未设置",//
                attributeValue5: "未设置",//
                attributeValue6: "",//
                attributeValue7: "mm",//
                attributeValue8: "",//
                attributeValue9: "",//
                attributeValue10: "/码",//
                attributeValue11: "",// 
                attributeValue12: "",//
                attributeValue13: "",//
                attributeValue14: "",//
                attributeValue15: "",//
                attributeValue16: "",//
            },
            HardwareproductAttribute: { //  五金饰品
                attributeValue1: "/个",//计价单位单位
                attributeValue2: "未设置",
                attributeValue3: "",//
                attributeValue3list: [],
                attributeValue4: "",//
                attributeValue5: "",//
                attributeValue6: "",//
                attributeValue7: "mm",//
                attributeValue8: "",//
                attributeValue9: "mm",//
                attributeValue10: "",//
                attributeValue11: "g",// 
                attributeValue12: "",//
                attributeValue13: "",//
                attributeValue14: "",//
                attributeValue15: "",//
                attributeValue16: "",//
            },
            HeelSoleUnitproductAttribute: {  // 鞋跟鞋底
                attributeValue1: "/双",//计价单位单位
                attributeValue2: "未设置",
                attributeValue3: "未设置",//
                attributeValue4: "未设置",//
                attributeValue5: "",//
                attributeValue6: "",//
                attributeValue7: "国际码",//
                attributeValue8: "未设置",//
                attributeValue9: "未设置",//
                attributeValue10: "",//
                attributeValue11: "",// 
                attributeValue12: "",//
                attributeValue13: "",//
                attributeValue14: "",//
                attributeValue15: "mm",//
                attributeValue16: "",//
            },
            ClothUnitLeatherproductAttribute: {  // 布料
                attributeValue1: "/码",//计价单位单位
                attributeValue2: "未设置",
                attributeValue3: "",//
                attributeValue4: "mm",//
                attributeValue5: "",//
                attributeValue6: "码",//
                attributeValue7: "",//
                attributeValue8: "码",//
                attributeValue9: "",//
                attributeValue10: "",//
                attributeValue11: "",// 
                attributeValue12: "",//
                attributeValue13: "",//
                attributeValue14: "",//
                attributeValue15: "",//
                attributeValue16: "",//
            },
            BraidedCordLeatherproductAttribute: {  // 编织索带
                attributeValue1: "/码",//计价单位单位
                attributeValue2: "未设置",
                attributeValue3: "未设置",//
                attributeValue4: "",//
                attributeValue5: "mm",//
                attributeValue6: "",//
                attributeValue7: "码",//
                attributeValue8: "",//
                attributeValue9: "码",//
                attributeValue10: "",//
                attributeValue11: "",// 
                attributeValue12: "",//
                attributeValue13: "",//
                attributeValue14: "",//
                attributeValue15: "",//
                attributeValue16: "",//
            },
            ShoesproductAttribute: {  // 鞋
                attributeValue1: "/双",//计价单位单位
                attributeValue2: "",
                attributeValue3: "",//
                attributeValue4: "未设置",//
                attributeValue5: "未设置",//
                attributeValue6: "",//
                attributeValue7: "",//
                attributeValue8: "国际码",//
                attributeValue9: "",//
                attributeValue10: "",//
                attributeValue11: "",// 
                attributeValue12: "",//
                attributeValue13: "",//
                attributeValue14: "",//
                attributeValue15: "",//
                attributeValue16: "",//
            },
            BagsSuitcasesproductAttribute: {  // 箱包
                attributeValue1: "/个",//计价单位单位
                attributeValue2: "未设置",
                attributeValue3: "",//
                attributeValue4: "",//
                attributeValue5: "",//
                attributeValue6: "",//
                attributeValue7: "",//
                attributeValue8: "cm",//
                attributeValue9: "",//
                attributeValue10: "寸",//
                attributeValue11: "",// 
                attributeValue12: "",//
                attributeValue13: "",//
                attributeValue14: "",//
                attributeValue15: "",//
                attributeValue16: "",//
            },
            OtherAttribute: {  // 其它
                attributeValue1: "",//计价单位单位
                attributeValue2: "",
                attributeValue3: "",//
                attributeValue4: "",//
                attributeValue5: "",//
                attributeValue6: "",//
                attributeValue7: "",//
                attributeValue8: "",//
                attributeValue9: "",//
                attributeValue10: "",//
                attributeValue11: "",// 
                attributeValue12: "",//
                attributeValue13: "",//
                attributeValue14: "",//
                attributeValue15: "",//
                attributeValue16: "",//
            },
            oldproductAttribute: {},
            enterpriseStateInfo: {},
            typeDescribe: '',
            productparametersList: true,
            platformsearchsettings: true,
            productdisplay: true,
            showImageUploadBox1: false,
            showImageUploadBox2: false,
            showImageUploadBox3: false,
            showImageUploadBox4: false,
            imageUploadTrends1: {
                'position': 'absolute',
                'right': '55px',
                'top': '180px'
            }
        };
    },
    watch: {
        "ProductLibraryAddForm.productAttribute"(newVal, oldVal) {
            this.oldproductAttribute = oldVal;
        },
        "ProductLibraryAddForm.productType"(newVal, oldVal) {
            if (this.oldproductAttribute) {
                switch (oldVal) {
                    case 1:
                        this.DermisUnitproductAttribute = JSON.parse(JSON.stringify(this.oldproductAttribute));
                        break;
                    case 2:
                        this.ArtificialLeatherproductAttribute = JSON.parse(JSON.stringify(this.oldproductAttribute));
                        break;
                    case 3:
                        this.HardwareproductAttribute = JSON.parse(JSON.stringify(this.oldproductAttribute));
                        break;
                    case 4:
                        this.HeelSoleUnitproductAttribute = JSON.parse(JSON.stringify(this.oldproductAttribute));
                        break;
                    case 5:
                        this.ClothUnitLeatherproductAttribute = JSON.parse(JSON.stringify(this.oldproductAttribute));
                        break;
                    case 6:
                        this.BraidedCordLeatherproductAttribute = JSON.parse(JSON.stringify(this.oldproductAttribute));
                        break;
                    case 7:
                        this.ShoesproductAttribute = JSON.parse(JSON.stringify(this.oldproductAttribute));
                        break;
                    case 8:
                        this.BagsSuitcasesproductAttribute = JSON.parse(JSON.stringify(this.oldproductAttribute));
                        break;
                    case 999:
                        this.OtherAttribute = JSON.parse(JSON.stringify(this.oldproductAttribute));
                        break;
                }
            }

        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo');
        if (this.queryType == 'AddSimilar') { //添加类似产品
            this.headerContent = '新增相似产品'
            let addSimilarProductForm = this.$store.state.addSimilarProductForm
            this.productTypeChange(addSimilarProductForm.productType)
            this.ProductLibraryAddForm = JSON.parse(JSON.stringify(addSimilarProductForm));
            this.oldproductAttribute = JSON.parse(JSON.stringify(addSimilarProductForm));
            this.ProductLibraryAddForm.rgbValue = '';
            // this.ProductLibraryAddForm.rgbValue = this.ProductLibraryAddForm.rgbValue ? '#' + this.ProductLibraryAddForm.rgbValue : ''
            // 十六进制转rgb
            // if (addSimilarProductForm.rgbValue) {
            //     let { R, G, B } = RGB2Lab.hexToRgba(addSimilarProductForm.rgbValue)
            //     let labValue = RGB2Lab.RGBToXYZ(R, G, B)
            //     this.labValue = labValue
            // }
        } else {  //  新增
            let newProductType = this.$getStorage('newProductType') ?? 1;
            this.ProductLibraryAddForm.productType = newProductType;
            if (newProductType != 999) {
                this.attributeValue1Options = UnitAccount.getAccount(newProductType);
                let { ProductBasicInformation, ProductParameter, ProductDisplay } = this
                this.ProductParameter = UnitAccount.getParameter(newProductType);
                this.ProductLibraryAddFormAr = [...ProductBasicInformation, ...ProductParameter, ...ProductDisplay];
                this.ProductLibraryAddForm.productAttribute = JSON.parse(JSON.stringify(UnitAccount.getProductAttribute(newProductType)));
                this.oldproductAttribute = JSON.parse(JSON.stringify(UnitAccount.getProductAttribute(newProductType)));
            }
            else if (newProductType == 999) {
                let { ProductBasicInformation, ProductDisplay } = this
                this.ProductLibraryAddFormAr = [...ProductBasicInformation, ...ProductDisplay];
                this.ProductLibraryAddForm.productAttribute = {
                    attributeValue1: "",
                    attributeValue2: "",
                    attributeValue3: "",
                    attributeValue4: "",
                    attributeValue5: "",
                    attributeValue6: "",
                    attributeValue7: "",
                    attributeValue8: "",
                    attributeValue9: "",
                    attributeValue10: "",
                    attributeValue11: "",
                    attributeValue12: "",
                    attributeValue13: "",
                    attributeValue14: "",
                    attributeValue15: "",
                    attributeValue16: "",
                }
            }
        }
        this.findSearchProductTypeList();
    },
    methods: {
        // 获取textarea内容
        getTextAreaValue(data) {
            this.ProductLibraryAddForm[data.type] = data.value
        },
        DecimalInputEvent(data) {
            data.type == 'unitPrice' ? this.ProductLibraryAddForm.unitPrice = data.value :
                this.ProductLibraryAddForm.productAttribute[data.type] = data.value
        },
        getValue(data) {
            this.ProductLibraryAddForm[data.type] = data.value
        },
        getValue2(data) {
            this.ProductLibraryAddForm.productAttribute[data.type] = data.value
        },
        // 获取产品类型
        async findSearchProductTypeList() {
            let data = {
                page: 1,
                size: 10
            }
            await this.$http.findSearchProductTypeList(data).then(res => {
                if (res.code == 200) {
                    let records = res.data.records,
                        odj = {},
                        options = [];
                    records.forEach(item => {
                        odj = {
                            value: item.productTypeId,
                            label: item.productTypeName,
                            typeDescribe: item.typeDescribe
                        }
                        options.push(odj);
                    });
                    options.shift(); //去除第一个
                    this.options = options;
                    this.options.forEach(item => {
                        if (this.ProductLibraryAddForm.productType == item.value) {
                            this.typeDescribe = item.typeDescribe;
                        }
                    })
                }
            })
        },
        productTypeChange(value) {
            this.ProductLibraryAddForm.productType = value;
            if (value != 999) {
                this.attributeValue1Options = UnitAccount.getAccount(value);
                let { ProductBasicInformation, ProductParameter, ProductDisplay } = this;
                this.ProductParameter = UnitAccount.getParameter(value);  //  获取表单列表
                this.ProductLibraryAddFormAr = [...ProductBasicInformation, ...ProductParameter, ...ProductDisplay];
                this.ProductLibraryAddForm.productAttribute = this.getDefaultValue(value);
            }
            else if (value == 999) {
                let { ProductBasicInformation, ProductDisplay } = this
                this.ProductParameter = [];
                this.ProductLibraryAddFormAr = [...ProductBasicInformation, ...ProductDisplay];
                this.ProductLibraryAddForm.productAttribute = this.getDefaultValue(value);
            }
        },
        // 获取默认值
        getDefaultValue(value) {
            let ProductAttribute = {};
            switch (value) {
                case 1:
                    ProductAttribute = this.DermisUnitproductAttribute
                    break;
                case 2:
                    ProductAttribute = this.ArtificialLeatherproductAttribute
                    break;
                case 3:
                    ProductAttribute = this.HardwareproductAttribute
                    break;
                case 4:
                    ProductAttribute = this.HeelSoleUnitproductAttribute
                    break;
                case 5:
                    ProductAttribute = this.ClothUnitLeatherproductAttribute
                    break;
                case 6:
                    ProductAttribute = this.BraidedCordLeatherproductAttribute
                    break;
                case 7:
                    ProductAttribute = this.ShoesproductAttribute
                    break;
                case 8:
                    ProductAttribute = this.BagsSuitcasesproductAttribute
                    break;
                case 999:
                    ProductAttribute = this.OtherAttribute
                    break;
            }
            return ProductAttribute
        },
        // 删除上传的图片
        deleteUploadImage(data) {
            if (this.queryType == 'modify') {
                switch (data) {
                    case 'img1Url':
                        this.orginImg1Url != '' ? this.ProductLibraryAddForm[data] = '' : this.ProductLibraryAddForm[data] = ''
                        break;
                    case 'img2Url':
                        this.orginImg2Url != '' ? this.ProductLibraryAddForm[data] = 'D' : this.ProductLibraryAddForm[data] = ''
                        break;
                    case 'img3Url':
                        this.orginImg3Url != '' ? this.ProductLibraryAddForm[data] = 'D' : this.ProductLibraryAddForm[data] = ''
                        break;
                    case 'img4Url':
                        this.orginImg4Url != '' ? this.ProductLibraryAddForm[data] = 'D' : this.ProductLibraryAddForm[data] = ''
                        break
                }
            } else {
                this.ProductLibraryAddForm[data] = ''
            }
        },
        // 删除上传的视频
        deleteUploadVideo() {
            if (this.queryType == 'modify') {
                this.orginVideoUrl != '' ? this.ProductLibraryAddForm.videoUrl = 'D' : this.ProductLibraryAddForm.videoUrl = '';
            } else {
                this.ProductLibraryAddForm.videoUrl = '';
            }
        },
        // 上传色值
        colorChange(value) {
            let that = this;
            if (value) {
                that.ProductLibraryAddForm.rgbValue = value ? value.substring(1) : ''
                // 十六进制转rgb
                let { R, G, B } = RGB2Lab.hexToRgba(value)
                let labValue = RGB2Lab.RGBToXYZ(R, G, B)
                that.labValue = labValue
            } else {
                that.ProductLibraryAddForm.rgbValue = '';
                that.labValue = {
                    labL: '',
                    labA: '',
                    labB: ''
                }
            }
        },
        // 选择产品单价
        selectValuationCurrency(value) {
            this.ProductLibraryAddForm.valuationCurrency = value;
        },
        // 计价单位
        selectAttributeValue1(value) {
            this.ProductLibraryAddForm.productAttribute.attributeValue1 = value;
        },
        // 工艺
        handleAttributeValue7Change(value) {
            this.ProductLibraryAddForm.productAttribute.attributeValue7 = value
        },
        // 材质
        handleAttributeValue2Change(value) {
            this.ProductLibraryAddForm.productAttribute.attributeValue2 = value
        },
        // 张幅单位
        selectAttributeValue13(value) {
            this.ProductLibraryAddForm.productAttribute.attributeValue13 = value;
        },
        // 等级
        selectAttributeValue8(value) {
            this.ProductLibraryAddForm.productAttribute.attributeValue8 = value;
        },
        // 3.工艺
        HardwareattributeValue3(value) {
            this.ProductLibraryAddForm.productAttribute.attributeValue3list = value;
            this.ProductLibraryAddForm.productAttribute.attributeValue3 = value.join("·");
        },
        FormData(params, type) {
            let that = this;
            var formData = new FormData();
            formData.append("userId", parseInt(that.$store.state.userId));
            formData.append("token", that.$getStorage("token"));
            formData.append("file", params.file);
            that.uploadProductOssFile(formData, type);
        },
        FormData2(formData, type) {
            let that = this;
            formData.append("userId", parseInt(that.$store.state.userId));
            formData.append("token", that.$getStorage("token"));
            that.uploadProductOssFile(formData, type);
        },
        // 产品图片视频上传接口
        uploadProductOssFile(formData, type) {
            let that = this;
            that.$http.uploadProductOssFile(formData).then(res => {
                if (type == "img1Url") {
                    that.showImageUploadBox1 = false;
                    that.ProductLibraryAddForm.img1Url = res.data;
                    that.clearItemValidate("img1Url");
                }
                if (type == "img2Url") {
                    that.showImageUploadBox2 = false;
                    that.ProductLibraryAddForm.img2Url = res.data;
                }
                if (type == "img3Url") {
                    that.showImageUploadBox3 = false;
                    that.ProductLibraryAddForm.img3Url = res.data;
                }
                if (type == "img4Url") {
                    that.showImageUploadBox4 = false;
                    that.ProductLibraryAddForm.img4Url = res.data;
                }
                if (type == "videoUrl") {
                    that.ProductLibraryAddForm.videoUrl = res.data;
                    that.isShowUploadVideo = true;
                    that.videoFlag = false;
                    that.videoUploadPercent = 0;
                }
                that.$common.message(res.msg, "success");
            });
        },
        // 清除某一项的验证
        clearItemValidate(value) {
            this.$refs.ProductLibraryAddForm.fields.map(i => {
                if (i.prop == value) {
                    i.clearValidate();
                }
            });
        },
        // 监听请求
        ListeningRequest() {
            let that = this;
            if (!that.timer) {
                that.count = that.TIME_COUNT;
                that.timer = setInterval(() => {
                    if (that.count > 0) {
                        that.count--;
                    } else {
                        clearInterval(that.timer);
                        that.timer = null;
                        return that.$confirm('已与服务器断开连接，产品上传可能失败，请返回列表确认上传结果。', '提示', {
                            confirmButtonText: '返回上一页',
                            showCancelButton: false,
                            showClose: false,
                            distinguishCancelAndClose: true,
                            closeOnClickModal: false,//不可通过点击遮罩关闭
                            closeOnPressEscape: false//不可通过按下 ESC 键关闭
                        }).then(() => {
                            that.$router.go(-1);
                        }).catch(() => { });
                    }
                }, 1000)
            }
        },
        // 新增产品
        addProduct(form) {
            let that = this;
            that.$confirm('确定新增产品？', '提示').then(() => {
                that.$refs[form].validate((valid) => {
                    if (valid) {
                        let { productType, productName, productNumber, colorStr, rgbValue, unitPrice, valuationCurrency, openSearchFlag,
                            shortageFlag, img1Url, img2Url, img3Url, img4Url, videoUrl, introduction, productAttribute } = that.ProductLibraryAddForm,
                            { labL, labA, labB } = that.labValue;
                        if (productType == 1) {
                            productAttribute.attributeValue7 = productAttribute.attributeValue7 ? productAttribute.attributeValue7.join("->") : productAttribute.attributeValue7;
                            productAttribute.attributeValue2 = productAttribute.attributeValue2 ? productAttribute.attributeValue2.join("->") : productAttribute.attributeValue2;
                        } else if (productType == 999) {
                            productAttribute.attributeValue1 = productAttribute.attributeValue1 ? "/" + productAttribute.attributeValue1 : '/';
                        }
                        rgbValue = rgbValue.indexOf('#') != -1 ? rgbValue.substring(1) : rgbValue;
                        that.$http.addProduct({
                            lastModifyUserId: parseInt(that.$store.state.userId),
                            token: that.$getStorage('token'),
                            productType,
                            productName,
                            productNumber,
                            colorStr,
                            rgbValue,
                            unitPrice: new Decimal(unitPrice),
                            valuationCurrency,
                            openSearchFlag,
                            shortageFlag,
                            img1Url,
                            img2Url,
                            img3Url,
                            img4Url,
                            videoUrl,
                            introduction,
                            productAttribute,
                            labL: labL ?? '',
                            labA: labA ?? '',
                            labB: labB ?? ''
                        }).then(res => {
                            that.ListeningRequest()
                            if (res.code == 200) {
                                that.$common.message(res.msg, 'success');
                                if (that.queryType == 'AddSimilar') {
                                    that.$common.DelayedBackTow();
                                    that.$setStorage('newProductType', productType)
                                } else {
                                    that.$router.push('/ProductLibrary');
                                    if (that.headerContent == '新增产品') {
                                        that.$setStorage('newProductType', productType)
                                    }

                                }
                            }
                        })
                    }
                })
            }).catch(() => { })
        },
        // 修改产品接口
        reviseEnterpriseProduct() {
            let that = this,
                { productType, productName, productNumber, colorStr, unitPrice, valuationCurrency, openSearchFlag, productId,
                    shortageFlag, img1Url, img2Url, img3Url, img4Url, videoUrl, introduction, productAttribute } = that.ProductLibraryAddForm,
                { labL, labA, labB } = that.labValue,
                rgbValue = that.ProductLibraryAddForm.rgbValue ? that.ProductLibraryAddForm.rgbValue : '-1008611';
            if (productType == 1) {
                productAttribute.attributeValue7 = productAttribute.attributeValue7 ? productAttribute.attributeValue7.join("->") : productAttribute.attributeValue7;
                productAttribute.attributeValue2 = productAttribute.attributeValue2 ? productAttribute.attributeValue2.join("->") : productAttribute.attributeValue2;
            } else if (productType == 999) {
                productAttribute.attributeValue1 = productAttribute.attributeValue1 ? '/' + productAttribute.attributeValue1 : "/";
            }
            rgbValue = rgbValue.indexOf('#') != -1 ? rgbValue.substring(1) : rgbValue;
            let orginProductLibraryAddForm = that.$getStorage('ProductLibraryForm'),
                orginImg1 = orginProductLibraryAddForm.img1Url,
                orginImg2 = orginProductLibraryAddForm.img2Url,
                orginImg3 = orginProductLibraryAddForm.img3Url,
                orginImg4 = orginProductLibraryAddForm.img4Url;
            // 修改时，如果图片没有修改成新的就传N
            img1Url = img1Url && img1Url == orginImg1 ? "N" : img1Url;
            img2Url = img2Url && img2Url == orginImg2 ? "N" : img2Url;
            img3Url = img3Url && img3Url == orginImg3 ? "N" : img3Url;
            img4Url = img4Url && img4Url == orginImg4 ? "N" : img4Url;
            let data = {
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                productType,
                productName,
                productId,
                productNumber,
                colorStr,
                rgbValue,
                unitPrice,
                valuationCurrency,
                openSearchFlag,
                shortageFlag,
                img1Url,
                img2Url,
                img3Url,
                img4Url,
                videoUrl,
                introduction,
                productAttribute,
                labL: labL ?? '',
                labA: labA ?? '',
                labB: labB ?? ''
            };
            that.$http.reviseEnterpriseProduct(data).then(res => {
                that.ListeningRequest();
                if (res.code == 200) {
                    that.$common.message(res.msg, 'success')
                    that.$common.DelayedBack();
                }
            })
        },
        // 修改产品
        modifyProduct(form) {
            let that = this;
            that.$confirm('确定修改该产品信息？', '提示').then(() => {
                that.$refs[form].validate((valid) => {
                    if (valid) {
                        that.reviseEnterpriseProduct()
                    }
                })
            }).catch(() => {
            })
        },

        // 删除产品
        deleteProduct(productId) {
            let that = this;
            that.$confirm('确定删除该产品？', '提示').then(() => {
                that.$http.removeEnterpriseProduct({
                    lastModifyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    productId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBackTow();
                    }
                })
            }).catch(() => { })
        },
        // 关闭上传图片盒子
        closeUploadBox(value) {
            this.showImageUploadBox1 = value;
            this.showImageUploadBox2 = value;
            this.showImageUploadBox3 = value;
            this.showImageUploadBox4 = value;
        },
    },
    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null;
    }
}

</script>
<style lang='less' scoped>
.ProductLibraryAdd {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .ProductLibrary-Add-Form {
            .formTitle {
                width: 100%;
                margin-bottom: 22px;
                border-left: 4px solid #0363FA;
                padding-left: 10px;
                font-size: 24px;
                font-weight: 500;
                color: #333333;
            }

            .formTitle1 {
                width: 150px;
                height: 30px;
                border-left: 4px solid #0363FA;
                padding-left: 10px;
                font-size: 24px;
                font-weight: 500;
                color: #333333;
            }

            .deploymentBtn {
                width: 80px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                color: #FFF;
                background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
                border-radius: 15px;
                cursor: pointer;
            }

            .productTypeItem {
                width: 80px;
                height: 40px;
                border-radius: 20px;
                text-align: center;
                cursor: pointer;
                margin-right: 10px;
                margin-bottom: 10px;
            }

            .selectY {
                background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
                color: #FFF;
            }

            .selectN {
                border: 1px solid #333333;
                color: #333
            }

            .typeDescribe {
                width: 550px;
                height: 30px;
                background: url('../../../assets/supply/bg_half_blue.png');
                background-repeat: no-repeat;
                background-position: center bottom;
                background-size: 550px 10px;
                // background-position: 0px 2px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
            }

            /deep/ .select {
                width: 100%;
            }

            .delete-btn {
                position: absolute;
                left: 210px;
                top: -10px;
                border: 1px solid #333;
                border-radius: 50%;
                padding: 2px;
                color: #333;
                background: #fff;
            }

            .delete-btn:hover {
                background: red;
                border: 1px solid red;
                color: #fff;
            }

        }

        .add-btns {
            .sure {
                width: 160px;
                height: 50px;
                background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
                border-radius: 10px;
                color: #FFF;
                font-size: 16px;
                line-height: 50px;
                text-align: center;
                cursor: pointer;
            }

            .back {
                width: 160px;
                height: 50px;
                background: #EFF4FA;
                border-radius: 10px;
                color: #0363FA;
                font-size: 16px;
                line-height: 50px;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}
</style>
